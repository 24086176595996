<template>
	<div class="clue-map">
		<w-navTab titleText="案发位置"></w-navTab>
		<div class="head-map">
			<div id="map"></div>
		</div>
		<div class="address">
			<div class="address-text">{{address}}</div>
		</div>
		<div class="footer">
			<div class="footer-button" @click="onSelect">确定</div>
		</div>
	</div>
</template>

<script>
	import WechatVendor from '@/vendor/wechat';
	import Event from '@/store/event';
	export default {
		name: "ClueMap",
		data() {
			return {
				map: null,
				marker: null,
				address: '请拖动地图选择案发位置',
				center: {}
			}
		},
		created() {
		},
		mounted() {
			this.createMap(28.452694, 117.944584)

			setTimeout(() => {
				WechatVendor.getLocation().then(result => {
					this.map.setCenter(new TMap.LatLng(result.latitude, result.longitude))
				})
			}, 300)
		},
		methods: {
			createMap(latitude, longitude) {

				if (this.map !== null) {
					return false;
				}

				this.geocoder = new TMap.service.Geocoder()
				this.center = new TMap.LatLng(latitude, longitude);

				this.map = new TMap.Map('map', {
					zoom: 15,
					center: this.center,
					showControl: true
				});

				this.marker = new TMap.MultiMarker({
					map: this.map,
					geometries: [{
						id: 'location',
						position: this.center
					}]
				})

				this.map.on('pan', result => {
					this.marker.updateGeometries({
						id: 'location',
						position: this.map.getCenter()
					})
				})

				this.map.on('panend', result => {
					this.geocoder.getAddress({
						location: this.map.getCenter()
					}).then(search => {
						this.center = this.map.getCenter()
						this.address = search.result.address
					}, error => {
						console.log(error)
					})
				})
			},
			onSelect() {
				let params = {
					latitude: this.center.lat,
					longitude: this.center.lng,
					address: this.address,
				}
				this.$router.back()
				Event.$emit('onClueMap', params);
			}
		}
	}
</script>

<style scoped lang="less">
	.clue-map {
		width: 100%;
		min-height: 100vh;
		background: #fff;
		
		.head-map {
			width: 100%;
			#map {
				width: 100%;
				height: calc(100vh - 120px);
			}
		}
		
		.address{
			position: fixed;
			bottom: 70px;
			left: 0;
			padding: 0 10px;
			width: 100%;
			box-sizing: border-box;
			.address-text{
				font-weight: bold;
				font-size: 15px;
				line-height: 22px;
				text-align: center;
				color: #333;
				padding: 11px 0;
				background: rgba(0, 0, 0, 0.1);
				border-radius: 4px;
			}
		}
		
		.footer{
			position: fixed;
			bottom: 12px;
			left: 0;
			padding: 0 10px;
			width: 100%;
			box-sizing: border-box;
			.footer-button{
				font-weight: bold;
				font-size: 15px;
				line-height: 22px;
				text-align: center;
				color: #FFF;
				padding: 11px 0;
				background: linear-gradient(92.48deg, #3377FF 0%, #3377FF 100%);
				box-shadow: 0px 2px 3px rgba(116, 111, 243, 0.35);
				border-radius: 4px;
			}
		}
	}
</style>
